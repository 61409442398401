import React, { useEffect, useRef } from "react";
import "./Chat.scss";
import { useStoreState } from "easy-peasy";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Textbox from "../../Components/TextBox/Textbox";
import Chatbox from "../../Components/ChatBox/Chatbox";
// import ChatboxMini from "../../Components/ChatBox/ChatboxMini";
import Modal from "../../Components/Modal/Modal";
import { motion } from "framer-motion";
import Paper from "@mui/material/Paper";
import LiveAgent from "../../Components/LiveAgent/LiveAgent";

const theme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#000",
      contrastText: "#FFF",
    },
    secondary: {
      main: "#FFF",
      contrastText: "#000",
    },
  },
});

const Chat = (props) => {
  const divRef = useRef(null);

  //Global States
  const navPCHeight = useStoreState((state) => state.navPCHeight);
  const colorMode = useStoreState((state) => state.colorMode);
  const chats = useStoreState((state) => state.chats);
  const loggedIn = useStoreState((state) => state.loggedIn);
  const liveSupport = useStoreState((state) => state.liveSupport);
  // const botData = useStoreState((state) => state.botData);
  // const setSetMenuItem = useStoreState((state) => state.setMenuItem);

  //Local States
  const [loaded, setLoaded] = React.useState(false);
  const [showModal3, setShowModal3] = React.useState(false);
  const [chatBoxHeight, setChatBoxHeight] = React.useState(0);
  const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);

  //Modal 3
  const openModal3 = () => {
    setShowModal3(true);
  };

  const closeModal3 = () => {
    setShowModal3(false);
  };

  const scrollToBottom = () => {
    // let element;
    // liveSupport ? (element = document.getElementById("chat-container")?.previousElementSibling) : (element = document.getElementById("chat-container").previousElementSibling);
    const element =
      document.getElementById("chat-container")?.previousElementSibling;
    element?.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  useEffect(() => {
    setWindowWidth(window.innerWidth);
    // !loggedIn && openModal3();
    setChatBoxHeight(document.getElementById("chatbox")?.offsetHeight);
    setTimeout(() => {
      setLoaded(true);
    }, 1000);
  }, []);

  useEffect(() => {
    scrollToBottom();
  }, [chats]);

  return (
    <ThemeProvider theme={theme}>
      {/* <div
        className="floating-suggestions"
        style={{
          marginTop: `${navPCHeight + navPCHeight / 2}px`,
          zIndex: "100",
        }}
      >
        {!showModal3 &&
          botData["suggestions"][0][setSetMenuItem]?.map((item, index) => (
            <motion.div
              onClick={() => {
                !loggedIn && openModal3();
              }}
              key={index}
              initial={{ y: 10, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              exit={{ y: -10, opacity: 0 }}
              transition={{
                duration: 0.2,
                delay: loaded ? 0 : index * 0.1,
              }}
            >
              <Suggestions
                text={item}
                onClick={() => {
                  document.getElementById("chat-input").value = item;
                  document.getElementById("chat-input").focus();
                }}
              />
            </motion.div>
          ))}
      </div> */}

      <div
        className="chat-wrapper"
        onClick={() => {
          !loggedIn && openModal3();
        }}
        style={{
          minHeight: `calc(100vh - ${navPCHeight}px)`,
          marginTop: `${navPCHeight}px`,
          backgroundColor: colorMode === "light" ? "#0000" : "#FFF",
        }}
      >
        <motion.div
            className="create-bot-button"
            style={{
              marginLeft: windowWidth > 425 && `${navPCHeight}px`,
            }}
            whileHover={{ scale: 1.06 }}
            whileTap={{ scale: 1 }}
            onClick={() => {
              window.open("https://getzapmail.com/ai-website/", "_blank");
            }}
          >
            Create your own AI website
            
          </motion.div>
        <div
          className="chat-box-wrapper"
          style={{
            marginTop: windowWidth > 425 && `${navPCHeight}px`,
            height: windowWidth > 425 && `calc(100vh - ${navPCHeight}px)`,
          }}
        >
          {liveSupport && <LiveAgent page={props.page === 0 ? 0 : 1}/>}
          <div
            className="chat-box"
            style={{
              maxHeight: `calc(100vh - ${navPCHeight}px)`,
            }}
          >
            <div
              style={{
                maxHeight:
                  windowWidth > 425
                    ? `calc(100vh - ${navPCHeight + chatBoxHeight}px)`
                    : `calc(100vh - ${46}px)`,
                overflowY: "auto",
                paddingTop: "1rem",
                scrollBehavior: "smooth",
              }}
              id="chats"
            >
              <Modal
                title="Hey nice to meet you!"
                show={showModal3}
                onClose={closeModal3}
                signUpFlow
                message={" I'm Zappy, how should I address you going forward?"}
              />
              <Paper
                sx={{
                  backgroundColor: "transparent",
                  border: "none",
                  boxShadow: "none",
                }}
              >
                {/* {!loggedIn && (
                  <>
                    <motion.div
                      key="1"
                      initial={{ y: 10, opacity: 0 }}
                      animate={{ y: 0, opacity: 1 }}
                      exit={{ y: -10, opacity: 0 }}
                      transition={{
                        duration: 0.2,
                        delay: loaded ? 0 : 1 * 0.1,
                      }}
                    >
                      <div className="chat-boxes-left">
                        <Textbox
                          text="Hi im zappy!  ^-^"
                          type="AI"
                        />
                      </div>
                    </motion.div>
                    <motion.div
                      key="1"
                      initial={{ y: 10, opacity: 0 }}
                      animate={{ y: 0, opacity: 1 }}
                      exit={{ y: -10, opacity: 0 }}
                      transition={{
                        duration: 0.2,
                        delay: loaded ? 0 : 2 * 0.1,
                      }}
                    >
                      <div className="chat-boxes-left">
                        <Textbox
                          text="Lets get to know each other a bit better."
                          type="AI"
                        />
                      </div>
                    </motion.div>
                    <motion.div
                      key="1"
                      initial={{ y: 10, opacity: 0 }}
                      animate={{ y: 0, opacity: 1 }}
                      exit={{ y: -10, opacity: 0 }}
                      transition={{
                        duration: 0.2,
                        delay: loaded ? 0 : 3 * 0.1,
                      }}
                    >
                      <div className="chat-boxes-left">
                        <Textbox
                          text="We’ll start with a few questions."
                          type="AI"
                        />
                      </div>
                    </motion.div>
                    <motion.div
                      key="1"
                      initial={{ y: 10, opacity: 0 }}
                      animate={{ y: 0, opacity: 1 }}
                      exit={{ y: -10, opacity: 0 }}
                      transition={{
                        duration: 0.2,
                        delay: loaded ? 0 : 4 * 0.1,
                      }}
                    >
                      <div className="chat-boxes-left">
                        <Textbox
                          text="What’s your name?"
                          type="AI"
                        />
                      </div>
                    </motion.div>
                    <motion.div
                      key="1"
                      initial={{ y: 10, opacity: 0 }}
                      animate={{ y: 0, opacity: 1 }}
                      exit={{ y: -10, opacity: 0 }}
                      transition={{
                        duration: 0.2,
                        delay: loaded ? 0 : 5 * 0.1,
                      }}
                    >
                      <div className="chat-boxes-left">
                        <ChatboxMini username/>
                      </div>
                    </motion.div>
                    <motion.div
                      key="1"
                      initial={{ y: 10, opacity: 0 }}
                      animate={{ y: 0, opacity: 1 }}
                      exit={{ y: -10, opacity: 0 }}
                      transition={{
                        duration: 0.2,
                        delay: loaded ? 0 : 6 * 0.1,
                      }}
                    >
                      <div className="chat-boxes-left">
                        <Textbox
                          text="What’s your email?"
                          type="AI"
                        />
                      </div>
                    </motion.div>
                    <motion.div
                      key="1"
                      initial={{ y: 10, opacity: 0 }}
                      animate={{ y: 0, opacity: 1 }}
                      exit={{ y: -10, opacity: 0 }}
                      transition={{
                        duration: 0.2,
                        delay: loaded ? 0 : 5 * 0.1,
                      }}
                    >
                      <div className="chat-boxes-left">
                        <ChatboxMini email/>
                        <font>Do not share my email</font>
                      </div>
                    </motion.div>
                  </>
                )} */}
                {chats.map((item, index) =>
                  item == null ? null : (
                    <motion.div
                      key={index + "chat"}
                      id={
                        index === chats.length - 1
                          ? "chat-container"
                          : chats.length - 2
                          ? "before-last"
                          : null
                      }
                      ref={index === chats.length - 1 ? divRef : null}
                      initial={{ y: 10, opacity: 0 }}
                      animate={{ y: 0, opacity: 1 }}
                      exit={{ y: -10, opacity: 0 }}
                      transition={{
                        duration: 0.2,
                        delay: loaded ? 0 : (chats.length / (index + 1)) * 0.1,
                      }}
                    >
                      <div
                        className={
                          item.type === "AI"
                            ? "chat-boxes-left"
                            : "chat-boxes-right"
                        }
                      >
                        <Textbox
                          text={item.text}
                          type={item.type}
                          // new={item.newChat}
                          thinking={item.thinking}
                        />
                      </div>
                    </motion.div>
                  )
                )}
              </Paper>
            </div>
            <motion.div
              onClick={() => {
                !loggedIn && openModal3();
              }}
              id="chatbox"
              initial={{ opacity: 0, scale: 0.5 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{
                duration: 0.3,
                ease: [0, 0.71, 0.2, 1.01],
                scale: {
                  type: "spring",
                  damping: 10,
                  stiffness: 100,
                  restDelta: 0.001,
                },
              }}
            >
              <Chatbox page={props.page === 0 ? 0 : 1} />
            </motion.div>
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
};
export default Chat;
