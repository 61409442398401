import React, { useEffect } from "react";
import "./FaceOne.scss";

const FaceOne = () => {
  const triggerEye1Animation = () => {
    const eye1 = document.querySelectorAll('[id="eye-content1"]');
    const eye2 = document.querySelectorAll('[id="eye-content2"]');
    for (let i = 0; i < eye1.length; i++) {
      eye1[i].classList.add("invisible");
      eye2[i].classList.add("visible");
    }
    setTimeout(() => {
      for (let i = 0; i < eye1.length; i++) {
        eye1[i].classList.remove("invisible");
        eye2[i].classList.remove("visible");
      }
    }, Math.floor(Math.random() * (1000 - 990 + 1) + 200));
  };

  useEffect(() => {
    // Function to generate a random interval between 2 to 10 seconds
    const getRandomInterval = () => {
      return Math.floor(Math.random() * (10000 - 2000 + 1) + 2000);
    };

    // Trigger the animation at random intervals
    const interval = setInterval(() => {
      triggerEye1Animation();
    }, getRandomInterval());

    // Clean up the interval when the component unmounts
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="face-one">
      <div className="eye1">
        <div className="eye-content1" id="eye-content1">
          ^
        </div>
        <div className="eye-content2" id="eye-content2">
          -
        </div>
      </div>
      <div className="mouth">_</div>
      <div className="eye2">
        <div className="eye-content1" id="eye-content1">
          ^
        </div>
        <div className="eye-content2" id="eye-content2">
          -
        </div>
      </div>
    </div>
  );
};

export default FaceOne;
