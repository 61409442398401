import React from "react";
import "./WelcomePage.scss";
import Lottie from "lottie-react";
import WelcomPageLottie from "../../Assets/Animations/welcomPage.json";
import ChatboxMini from "../../Components/ChatBox/ChatboxMini";
import { motion } from "framer-motion";
import { TypeAnimation } from "react-type-animation";

const WelcomePage = (props) => {
  return (
    <div className="welcome-wrapper">
      <motion.div
        initial={{ opacity: 1, y: +200 }}
        animate={{ opacity: 1, y: 0}}
        transition={{ duration: 0.5, delay: 1 }}
      >
        <div className="text-big">
        <TypeAnimation
                    sequence={[
                      "Welcome to Zappy",
                      0,
                    ]}
                    wrapper="div"
                    cursor={false}
                    speed={50}
                    repeat={0}
                    style={{ whiteSpace: "pre-line" }}
                  />
        </div>
      </motion.div>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5, delay: 1.4 }}
      >
        Your own personal AI Assistant
      </motion.div>
      <motion.div
      style={{width:'100%', display:'flex', justifyContent:'center', alignItems:'center'}}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5, delay: 1.5 }}
      >
        <div className="lottie">
          {" "}
          <Lottie animationData={WelcomPageLottie} />
          {props.page === "Not found" && (
            <div className="error">Bot not Found. Please enter a valid ID</div>
          )}
          <ChatboxMini
            WelcomePage
            fullWidth
            username
            placeHolder={"Enter bot ID"}
          />
        </div>
      </motion.div>
    </div>
  );
};

export default WelcomePage;
