import { action } from "easy-peasy";

const model = {
  // State
  colorMode: "light",
  pageNumber: 0,
  navPCHeight: 0,
  navMobileHeight: 0,
  chats: [],
  businessName: "",
  currentChat: "",
  setMenuItem: "",
  menuButtonPressed: false,
  loggedIn: false,
  username: "",
  email: "",
  newChat: false,
  botData: {
    name: "Bot",
    pages: [],
    suggestions: [],
  },
  liveSupport: false,

  // Actions
  setColorMode: action((state, colorMode) => {
    state.colorMode = colorMode;
  }),

  setPageNumber: action((state, pageNumber) => {
    state.pageNumber = pageNumber;
  }),

  setNavPCHeight: action((state, navPCHeight) => {
    state.navPCHeight = navPCHeight;
  }),

  setChats: action((state, chats) => {
    state.chats = chats;
  }),

  setBusinessName: action((state, businessName) => {
    state.businessName = businessName;
  }),

  setCurrentChat: action((state, currentChat) => {
    state.currentChat = currentChat;
  }),

  setSetMenuItem: action((state, setMenuItem) => {
    state.setMenuItem = setMenuItem;
  }),

  setMenuButtonPressed: action((state, menuButtonPressed) => {
    state.menuButtonPressed = menuButtonPressed;
  }),

  setLoggedIn: action((state, loggedIn) => {
    state.loggedIn = loggedIn;
  }),

  setUsername: action((state, username) => {
    state.username = username;
  }),

  setEmail: action((state, email) => {
    state.email = email;
  }),

  setNewChat: action((state, newChat) => {
    state.newChat = newChat;
  }),

  setBotData: action((state, botData) => {
    state.botData = botData;
  }),

  setLiveSupport: action((state, liveSupport) => {
    state.liveSupport = liveSupport;
  }),

  // messageRestHelper: thunk(async (actions, payload, { getState }) => {
  //     return new Promise(async resolve => {
  //       let state = getState();
  //       let defaultHeaders = {
  //         'x-access-token': state.accessToken,
  //       };
  //       await api
  //         .request({
  //           url: payload.url,
  //           method: payload.method,
  //           data: payload.data ? payload.data : null,
  //           headers: {
  //             ...api.defaults.headers,
  //             ...defaultHeaders,
  //           },
  //         })
  //         .then(response => {
  //           resolve(response);
  //         })
  //         .catch(error => {
  //           if (error.response.status === 500) {
  //             actions.setError(true);
  //           } else {
  //             actions.setError(false);
  //           }
  //         });
  //     });
  //   }),
};

export default model;
