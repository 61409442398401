import React, { useEffect } from "react";
import "./Chatbox.scss";
import Send from "../../Assets/Icons/arrow.png";
import { useStoreState, useStoreActions } from "easy-peasy";
import { motion } from "framer-motion";
import { runCustomBot } from "../../Services/CustomBot/CustomBot_reqs";
import { useParams } from "react-router-dom";
import Suggestions from "../../Components/SuggestionBox/Suggestionbox";
// import Modal from "../Modal/Modal";

const Chatbox = (props) => {
  let { id } = useParams();

  if (props.page === 0) {
    id = process.env.REACT_APP_ZAPPY_ID;
  }

  //Global States
  const botData = useStoreState((state) => state.botData);
  const setCurrentChat = useStoreActions((actions) => actions.setCurrentChat);
  const setChats = useStoreActions((actions) => actions.setChats);
  const chats = useStoreState((state) => state.chats);
  const currentChat = useStoreState((state) => state.currentChat);
  const setNewChat = useStoreActions((actions) => actions.setNewChat);
  const loggedIn = useStoreState((state) => state.loggedIn);
  const menuButtonPressed = useStoreState((state) => state.menuButtonPressed);
  const setMenuButtonPressed = useStoreActions(
    (actions) => actions.setMenuButtonPressed
  );
  const setLiveSupport = useStoreActions((actions) => actions.setLiveSupport);

  //Local States
  const [loaded, setLoaded] = React.useState(false);
  const [replyReceived, setReplyReceived] = React.useState(true);
  const [isFocused, setIsFocused] = React.useState(false);

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      sendInput();
    }
  };

  const scrollToBottom = () => {
    const element = document.getElementById("chats");
    element.scrollTop = element.scrollHeight;
    element.scrollIntoView({ behavior: "smooth", block: "end" });
  };

  const sendInput = () => {
    setReplyReceived(false);
    if (currentChat === "") {
      return;
    }
    setChats([
      ...chats,
      { type: "USER", text: currentChat },
      { type: "AI", text: "...", thinking: true },
    ]);
    getReply();
    scrollToBottom();
  };

  const pattern =
    /contact them directly|contact|live agent|contact|live support agent/gi;

  const getReply = () => {
    runCustomBot({
      id: id,
      human_input: currentChat,
      email: localStorage.getItem("email"),
      username: localStorage.getItem("username"),
    })
      .then((res) => {
        setNewChat(true);
        document.getElementById("chat-input").value = "";
        setCurrentChat("");
        const contact = pattern.test(res.data.data.ai_msg);
        if (contact) {
          setLiveSupport(true);
        }
        setChats([
          ...chats,
          { type: "USER", text: currentChat, newChat: true },
          {
            type: "AI",
            text: res.data.data.ai_msg,
            newChat: true,
            live_agent: contact,
          },
          contact
            ? {
                type: "AI",
                text: "Please click on the following contact button to talk with a live agent.",
                newChat: true,
              }
            : null,
        ]);
        setReplyReceived(true);
        document.getElementById("chat-input").focus();
        setMenuButtonPressed(false);
      })
      .catch((err) => {
        console.log(err);
        document.getElementById("chat-input").value = currentChat;
        setChats([
          ...chats,
          {
            type: "AI",
            text: "Looks like I can't connect to the server right now. Please try again ...",
            newChat: true,
          },
          setReplyReceived(true),
        ]);
      });
  };

  useEffect(() => {
    setTimeout(() => {
      setLoaded(true);
    }, 1000);
    setIsFocused(true);
  }, []);

  useEffect(() => {
    document.getElementById("chat-input").value = currentChat;
    menuButtonPressed && sendInput();
  }, [currentChat]);

  return (
    <>
      <div className="chatbox-wrapper">
        <div className="chatbox">
          <div className="chatbox-suggestions">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                overflowX: "scroll",
              }}
            >
              {!isFocused &&
                botData["suggestions"][0]?.map((item, index) => (
                  <motion.div
                    key={index}
                    initial={{ y: 10, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    exit={{ y: -10, opacity: 0 }}
                    transition={{
                      duration: 0.2,
                      delay: loaded ? 0 : index * 0.1,
                    }}
                  >
                    <Suggestions
                      text={item}
                      onClick={() => {
                        document.getElementById("chat-input").value = item;
                        document.getElementById("chat-input").focus();
                      }}
                    />
                  </motion.div>
                ))}
            </div>
            <div
              className={`chat ${
                !isFocused && currentChat === "" ? "chat-shrink" : "chat-expand"
              }`}
              style={{
                border: currentChat !== "" && loggedIn && "1px solid #000",
              }}
              // onClick={() => {
              //   !loggedIn && props.openModal3();
              // }}
            >
              {" "}
              <input
                placeholder="Ask me anything..."
                disabled={replyReceived ? false : true}
                onKeyDown={(e) => {
                  handleKeyDown(e);
                }}
                id="chat-input"
                onChange={(e) => {
                  loggedIn && setCurrentChat(e.target.value)
                    // : props.openModal3();
                }}
                onFocus={() => {
                  setIsFocused(true);
                }}

                value={currentChat}
                // onBlur={() => {
                //   currentChat === "" && setIsFocused(false);
                // }}
              />
              <motion.div
                whileHover={{
                  scale: replyReceived && currentChat !== "" && 1.1,
                }}
                whileTap={{ scale: replyReceived && currentChat !== "" && 0.9 }}
                initial={{ scale: 0 }}
                animate={{ rotate: 360, scale: 1 }}
                transition={{
                  type: "spring",
                  stiffness: 260,
                  damping: 20,
                  delay: loaded ? 0 : 0.4,
                }}
                style={{
                  display: "flex",
                }}
                onClick={() => {
                  replyReceived && currentChat !== "" && sendInput();
                }}
              >
                <div
                  className={`send-button ${
                    !replyReceived || currentChat === ""
                      ? "send-button-deactive"
                      : "send-button-active"
                  }`}
                  style={{
                    backgroundColor:
                      !replyReceived || currentChat === "" ? "#848484" : "#000",
                  }}
                >
                  <img src={Send} alt="Send" />
                </div>
              </motion.div>
            </div>
          </div>
          <div className="chatbox-below-text">
            <font color="#909090">
              AI responses are generated based on available information and may
              not always be accurate or up to date.
            </font>
          </div>
        </div>
      </div>
    </>
  );
};
export default Chatbox;
