import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Zappy from "./Pages/Zappy";
import WelcomePage from "./Pages/WelcomePage/WelcomePage";

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="*" element={<WelcomePage page="Not found"/>} />
          {/* <Route path="/" element={<WelcomePage />} /> */}
          <Route path="/" element={<Zappy page={0} />} />
          <Route path="/:id" element={<Zappy page={1} />} />
          <Route path="/:id/chat" element={<Zappy page={2} />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
