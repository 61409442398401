import React, { useRef } from "react";
import { TypeAnimation } from "react-type-animation";
import "./Textbox.scss";
import { useStoreState } from "easy-peasy";
import Lottie from "lottie-react";
import Loading from "../../Assets/Animations/Loading.json";
import Markdown from 'react-markdown'

const Textbox = (props) => {
  const divRef = useRef(null);

  //Global States
  const newChat = useStoreState((state) => state.newChat);

  // const text2html = (text) => {
  //   const lines = text.split("\n");
  //   return lines.map((line, index) => (
  //     <span key={index}>
  //       {line}
  //       {index !== lines.length - 1 && <br />}
  //     </span>
  //   ));
  // };

  // const paragraphs = props.text.split('\n').map((paragraph, index) => (
  //   <p key={index}>{paragraph}</p>
  // ));

  return (
    <>
      <div className="textbox-wrapper">
        <div
          className="textbox"
          style={{
            backgroundColor: props.type === "USER" ? "#FFF" : "#EBEBEB",
            textAlign: props.type === "USER" ? "right" : "left",
            borderRadius:
              props.type === "USER"
                ? "10px 0px 10px 10px"
                : "0px 10px 10px 10px",
          }}
        >
          <div className="textbox-text">
            {newChat && props.type !== "USER" && props.new ? (
              <TypeAnimation
                sequence={[props.text.split('\\n').join('\n'), 0]}
                wrapper="div"
                speed={80}
                repeat={0}
                cursor={false}
                ref={divRef}
              />
            ) : props.thinking ? (
              <Lottie
                animationData={Loading}
                loop={true}
                className="lottie"
              />
            ) : (
              <Markdown >
                {props.text.split('\\n').join('\n')}
              </Markdown>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default Textbox;
