import axios from "axios";
import { protocol, url } from "../Urls";

export const createSupportRequest = (data) => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
      method: "post",
      baseURL: `${protocol}${url}`,
      url: "/createSupportRequest",
      data: data,
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getSupportRequest = (request_id) => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
      method: "get",
      baseURL: `${protocol}${url}`,
      url: "/getSupportRequest?support_request_id=" + request_id,
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getSupportRequests = (bot_id) => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
      method: "get",
      baseURL: `${protocol}${url}`,
      url: "/getSupportRequests?bot_id=" + bot_id,
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const updateSupportRequest = (data) => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
      method: "put",
      baseURL: `${protocol}${url}`,
      url: "/updateSupportRequest",
      data: data,
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const deleteSupportRequest = (data) => {
    return new Promise((resolve, reject) => {
      axios({
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
        method: "delete",
        baseURL: `${protocol}${url}`,
        url: "/deleteSupportRequest",
        data: data,
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
