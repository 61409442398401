import React from "react";
import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import Brightness4Icon from "@mui/icons-material/NightsStay";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useStoreActions, useStoreState } from "easy-peasy";
import { useParams } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import { SocialIcon } from "react-social-icons/component";
import "react-social-icons/facebook";
import "react-social-icons/linkedin";
import "react-social-icons/instagram";
import "./Navigation.scss";
import ArrowDown from "../../Assets/Icons/arrowDown.png";
import Modal from "../../Components/Modal/Modal";

const theme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#000",
      contrastText: "#FFF",
    },
    secondary: {
      main: "#FFF",
      contrastText: "#000",
    },
  },
});

const Navigation = () => {
  const { id } = useParams();

  //Global States
  const botData = useStoreState((state) => state.botData);
  const colorMode = useStoreState((state) => state.colorMode);
  const setColorMode = useStoreActions((actions) => actions.setColorMode);
  const navPCHeight = useStoreState((state) => state.navPCHeight);
  const setNavPCHeight = useStoreActions((actions) => actions.setNavPCHeight);
  const setPageNumber = useStoreActions((actions) => actions.setPageNumber);
  const setCurrentChat = useStoreActions((actions) => actions.setCurrentChat);
  const setMenuButtonPressed = useStoreActions(
    (actions) => actions.setMenuButtonPressed
  );
  const setSetMenuItem = useStoreActions((actions) => actions.setSetMenuItem);
  const businessName = useStoreState((state) => state.businessName);
  const loggedIn = useStoreState((state) => state.loggedIn);

  //Local States
  const [showMobileNav, setShowMobileNav] = React.useState(false);
  const [showModal, setShowModal] = React.useState(false);

  //Modal 3
  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  React.useEffect(() => {
    document.querySelector(".Navbar-pc").offsetHeight !== 0
      ? setNavPCHeight(document.querySelector(".Navbar-pc").offsetHeight)
      : setNavPCHeight(document.querySelector(".Navbar-mobile").offsetHeight);
  });

  //adding an event listner to close the opened menu in mobile view
  // document.addEventListener("click", function (event) {
  //   if (!event.target.closest(".menu-wrapper")) {
  //     document.getElementById("mobile-nav").classList.add("hide-mobile-nav");
  //     document
  //       .getElementById("basic-button")
  //       .classList.remove("show-mobile-nav");
  //   } else {
  //     document.getElementById("mobile-nav").classList.remove("hide-mobile-nav");
  //     document.getElementById("basic-button").classList.add("show-mobile-nav");
  //   }
  // });

  return (
    <ThemeProvider theme={theme}>
      <Modal
        title="Quick and free sign-up "
        show={showModal}
        onClose={closeModal}
        signUpFlow
        message={"Enter your email address and Username to continue"}
      />
      <AppBar
        className="Navbar-pc"
        position="static"
        sx={{
          backgroundColor:
            colorMode === "dark"
              ? botData["template"]["menu_background_dark"]
              : botData["template"]["menu_background"],
          position: "fixed",
          zIndex: "100",
          boxShadow:
            colorMode === "dark"
              ? "0 0 10px rgba(0, 0, 0, 0.5)"
              : "0 0 10px rgba(255, 255, 255, 0.5)",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div className="Navbar-wrapper">
            <div className="menu-wrapper">
              <div
                className="Navbar-logo"
                onClick={() => {
                  window.location.href = `/`;
                }}
              >
                {/* <AdbIcon
                  sx={{
                    color: colorMode === "dark" ? "#000" : "#fff",
                    fontSize: "2rem",
                  }}
                /> */}
                <img
                  src={`data:image/jpeg;base64,${botData["logo"]}`}
                  alt="logo"
                  style={{
                    width: "8rem",
                    // borderRadius: "50%",
                    margin: "-1rem 0",
                  }}
                />
                <div
                  style={{
                    fontWeight: "700",
                    color: colorMode === "dark" ? "#000" : "#fff",
                  }}
                >
                  {/* {businessName} */}
                </div>
              </div>
            </div>
            <div className="Navbar-menu">
              <div className="social-media-wrapper">
                <SocialIcon
                  network="facebook"
                  url="https://www.facebook.com/pages/Apollo-Hospitals-Colombo/132992656736729"
                  target="_blank"
                  bgColor="transparent"
                  fgColor={
                    colorMode === "dark"
                      ? botData["template"]["menu_text_color_dark"]
                      : botData["template"]["menu_text_color"]
                  }
                  className="social-media-icon"
                />
                <SocialIcon
                  network="linkedin"
                  url="https://lk.linkedin.com/company/apollocarecenter-sl?trk=public_profile_topcard-current-company"
                  target="_blank"
                  bgColor="transparent"
                  fgColor={
                    colorMode === "dark"
                      ? botData["template"]["menu_text_color_dark"]
                      : botData["template"]["menu_text_color"]
                  }
                  className="social-media-icon"
                />
                <SocialIcon
                  network="instagram"
                  url="https://www.instagram.com/p/Cy0YjEAiRJW/"
                  target="_blank"
                  bgColor="transparent"
                  fgColor={
                    colorMode === "dark"
                      ? botData["template"]["menu_text_color_dark"]
                      : botData["template"]["menu_text_color"]
                  }
                  className="social-media-icon"
                />
              </div>
              <div>
                {Object.keys(botData["pages"]).map((page, index) => (
                  <div className="menu-button">
                    <Button
                      id="basic-button"
                      onMouseOver={(e) => {
                        setSetMenuItem(page);
                      }}
                      onClick={() => {
                        setPageNumber(1);
                        !loggedIn && openModal();
                        if (loggedIn) {
                          setMenuButtonPressed(true);
                          setCurrentChat(page + " at " + businessName);
                          setSetMenuItem(page);
                        }
                      }}
                      key={index}
                      sx={{
                        color:
                          colorMode === "dark"
                            ? botData["template"]["menu_text_color_dark"]
                            : botData["template"]["menu_text_color"],
                        textTransform: "none",
                        fontWeight: "600",
                        fontFamily: "Space Grotesk",
                        fontSize: "1.2rem",
                      }}
                    >
                      {page}
                    </Button>
                    <ul className="submenu">
                      {botData["pages"][page]?.map((item, index) => (
                        <li
                          key={index}
                          onClick={() => {
                            !loggedIn && openModal();
                            setPageNumber(1);
                            if (loggedIn) {
                              setMenuButtonPressed(true);
                              setCurrentChat(
                                botData["pages"][page][index]["Question"]
                              );
                            }
                          }}
                        >
                          {botData["pages"][page][index]["MenuItem"]}
                        </li>
                      ))}
                    </ul>
                  </div>
                ))}
              </div>
              {/* <IconButton
                onClick={() => {
                  setColorMode(colorMode === "dark" ? "light" : "dark");
                  console.log(colorMode);
                }}
                color={colorMode === "dark" ? "secondary" : "primary"}
              >
                {colorMode === "dark" ? (
                  <Brightness7Icon />
                ) : (
                  <Brightness4Icon />
                )}
              </IconButton> */}
            </div>
          </div>
        </div>
      </AppBar>
      <AppBar
        className="Navbar-mobile"
        position="static"
        sx={{
          backgroundColor: colorMode === "dark" ? "#fff" : "#000",
          position: "fixed",
          zIndex: "100",
          boxShadow:
            colorMode === "dark"
              ? "0 0 10px rgba(0, 0, 0, 0.5)"
              : "0 0 10px rgba(255, 255, 255, 0.5)",
        }}
      >
        <div
          style={{
            width: "100%",
          }}
        >
          <div className="Navbar-wrapper">
            <div
              className="Navbar-logo"
              onClick={() => {
                window.location.href = `/${id}`;
              }}
            >
              {/* <AdbIcon
                  sx={{
                    color: colorMode === "dark" ? "#000" : "#fff",
                    fontSize: "2rem",
                  }}
                /> */}
              <img
                src={`data:image/jpeg;base64,${botData["logo"]}`}
                alt="logo"
                style={{
                  width: "5rem",
                  // borderRadius: "50%",
                  margin: "-2rem 0",
                }}
              />
              <div
                style={{
                  fontWeight: "700",
                  color: colorMode === "dark" ? "#000" : "#fff",
                }}
              >
                {/* {businessName} */}
              </div>
            </div>
            {/* <div className="Theme-toggler">
              <IconButton
                sx={{ mr: "2rem" }}
                onClick={() => {
                  setColorMode(colorMode === "dark" ? "light" : "dark");
                }}
                color={colorMode === "dark" ? "primary" : "secondary"}
              >
                {colorMode === "dark" ? (
                  <Brightness7Icon />
                ) : (
                  <Brightness4Icon />
                )}
              </IconButton>
            </div> */}
            <div className="menu-wrapper">
              <div className="Navbar-menu">
                <Button
                  id="basic-button"
                  onClick={() => {
                    setShowMobileNav(!showMobileNav);
                    console.log(showMobileNav);
                  }}
                >
                  <MenuIcon
                    sx={{
                      color: colorMode === "dark" ? "#000" : "#fff",
                      fontSize: "2rem",
                    }}
                  />
                </Button>
                <div
                  onClick={() => {
                    setShowMobileNav(false);
                  }}
                  className={`mobile-nav-2 ${
                    showMobileNav ? "show-mobile-nav" : "hide-mobile-nav"
                  }`}
                  style={{
                    marginTop: navPCHeight,
                    height: `calc(100vh - ${navPCHeight}px)`,
                  }}
                ></div>
                <div
                  className={`mobile-nav ${
                    showMobileNav ? "show-mobile-nav" : "hide-mobile-nav"
                  }`}
                  id="mobile-nav"
                  style={{
                    marginTop: navPCHeight,
                    height: `calc(100vh - ${navPCHeight}px)`,
                  }}
                >
                  <table width={"100%"}>
                    <tbody>
                      {Object.keys(botData["pages"]).map((page, index) => (
                        <div className="menu-button">
                          <div className="menu-button-wrapper">
                            <tr
                              style={{
                                width: "100%",
                              }}
                            >
                              <td
                                style={{
                                  width: "90%",
                                  textAlign: "left",
                                }}
                              >
                                <Button
                                  id="basic-button"
                                  onMouseOver={(e) => {
                                    setSetMenuItem(page);
                                  }}
                                  onClick={() => {
                                    setShowMobileNav(false);
                                    setPageNumber(1);
                                    if (loggedIn) {
                                      setMenuButtonPressed(true);
                                      setCurrentChat(
                                        page + " at " + businessName
                                      );
                                      setSetMenuItem(page);
                                    } else if (!loggedIn) {
                                      openModal();
                                    }
                                  }}
                                  key={index}
                                  sx={{
                                    color:
                                      colorMode === "dark"
                                        ? botData["template"][
                                            "menu_text_color_dark"
                                          ]
                                        : botData["template"][
                                            "menu_text_color"
                                          ],
                                    textTransform: "none",
                                    fontWeight: "600",
                                    fontFamily: "Space Grotesk",
                                    fontSize: "1.25rem",
                                    textAlign: "left",
                                  }}
                                >
                                  {page}
                                </Button>
                              </td>
                              <td
                                style={{
                                  width: "10%",
                                }}
                              >
                                <div>
                                  {botData["pages"][page].length !== 0 && (
                                    <img
                                      onClick={() => {
                                        !loggedIn && openModal();
                                        if (loggedIn) {
                                          document
                                            .getElementsByClassName(
                                              "submenu-mobile"
                                            )
                                            [index].classList.toggle(
                                              "show-submenu-mobile"
                                            );
                                        }
                                      }}
                                      src={ArrowDown}
                                    />
                                  )}
                                </div>
                              </td>
                            </tr>
                          </div>
                          <ul className="submenu-mobile">
                            {botData["pages"][page]?.map((item, index) => (
                              <li
                                key={index}
                                onClick={() => {
                                  setPageNumber(1);
                                  !loggedIn && openModal();
                                  if (loggedIn) {
                                    setMenuButtonPressed(true);
                                    setCurrentChat(
                                      botData["pages"][page][index]["Question"]
                                    );
                                    setShowMobileNav(false);
                                  }
                                }}
                              >
                                {botData["pages"][page][index]["MenuItem"]}
                              </li>
                            ))}
                          </ul>
                        </div>
                      ))}
                    </tbody>
                  </table>
                  <div className="social-media-wrapper">
                    <SocialIcon
                      network="facebook"
                      url="https://www.fb.com"
                      target="_blank"
                      bgColor="transparent"
                      fgColor={
                        colorMode === "dark"
                          ? botData["template"]["menu_text_color_dark"]
                          : botData["template"]["menu_text_color"]
                      }
                      className="social-media-icon"
                    />
                    <SocialIcon
                      network="linkedin"
                      url="https://www.linkedin.com"
                      target="_blank"
                      bgColor="transparent"
                      fgColor={
                        colorMode === "dark"
                          ? botData["template"]["menu_text_color_dark"]
                          : botData["template"]["menu_text_color"]
                      }
                      className="social-media-icon"
                    />
                    <SocialIcon
                      network="instagram"
                      url="https://www.instagram.com"
                      bgColor="transparent"
                      target="_blank"
                      fgColor={
                        colorMode === "dark"
                          ? botData["template"]["menu_text_color_dark"]
                          : botData["template"]["menu_text_color"]
                      }
                      className="social-media-icon"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AppBar>
    </ThemeProvider>
  );
};

export default Navigation;
