import React from "react";
import "./Suggestionbox.scss";
import { useStoreActions } from "easy-peasy";

const Suggestions = (props) => {
    //Global States
    const setCurrentChat = useStoreActions((actions) => actions.setCurrentChat);

  return (
    <>
      <div className="suggestionbox-wrapper" onClick={()=> {
        setCurrentChat(props.text);
      }}>
        <div className="suggestionbox">
          <div className="suggestionbox-text">
            {props.text}
          </div>
        </div>
      </div>
    </>
  );
};
export default Suggestions;
