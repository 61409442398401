import React from "react";
import Modal_live_support from "../../Components/Modal/Modal_live_support";
import Lottie from "lottie-react";
import './LiveAgent.scss'
import LiveAgentLottie from "../../Assets/Animations/contact_mark.json";

const LiveAgent = (props) => {
  const [showLiveSupportModal, setShowLiveSupportModal] = React.useState(false);

  //Live Support Modal
  const openLiveSupportModal = () => {
    setShowLiveSupportModal(true);
  };

  const closeLiveSupportModal = () => {
    setShowLiveSupportModal(false);
  };
  return (
    <>
      <Modal_live_support
        title="Contact Support"
        show={showLiveSupportModal}
        onClose={closeLiveSupportModal}
        message={"Confirm your details and our team will get back to you as soon as possible"}
        page={props.page}
      />
      <div className="live-agent" onClick={openLiveSupportModal}>
        <Lottie animationData={LiveAgentLottie} />
      </div>
    </>
  );
};
export default LiveAgent;
