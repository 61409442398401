import React, { useEffect } from "react";
import "./ChatboxMini.scss";
import Send from "../../Assets/Icons/arrow.png";
import { useStoreState, useStoreActions } from "easy-peasy";
import { motion } from "framer-motion";
import { runCustomBot } from "../../Services/CustomBot/CustomBot_reqs";
import { useParams } from "react-router-dom";

const ChatboxMini = (props) => {
  const { id } = useParams();

  //Global States
  const businessName = useStoreState((state) => state.businessName);
  const setCurrentChat = useStoreActions((actions) => actions.setCurrentChat);
  const setChats = useStoreActions((actions) => actions.setChats);
  const chats = useStoreState((state) => state.chats);
  const currentChat = useStoreState((state) => state.currentChat);
  const username = useStoreState((state) => state.username);
  const email = useStoreState((state) => state.email);
  const setNewChat = useStoreActions((actions) => actions.setNewChat);
  const loggedIn = useStoreState((state) => state.loggedIn);
  const setEmail = useStoreActions((actions) => actions.setEmail);
  const setUsername = useStoreActions((actions) => actions.setUsername);

  //Local States
  const [loaded, setLoaded] = React.useState(false);
  const [emailInput, setEmailInput] = React.useState("");
  const [usernameInput, setUsernameInput] = React.useState("");


  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      sendInput();
    }
  };

  const sendInput = () => {
    if (props.WelcomePage) {
      window.location.href = `/${document.getElementById("chat-input").value}`;
    }else{
    props.username && setUsername(usernameInput);
    props.email && setEmail(emailInput);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setLoaded(true);
    }, 1000);
  }, []);

  useEffect(() => {
    document.getElementById("chat-input").value = currentChat;
  }, [currentChat]);

  return (
    <>
      <div className="chatbox-wrapper-mini">
        <div className="chatbox">
          <div
            className="chat-mini"
            style={{
              border: (emailInput !== "" || usernameInput !=="") && "1px solid #000",
              width: props.fullWidth ? "100%" :'',
            }}
          >
            {" "}
            <input
            placeholder={props.placeHolder}
              // disabled={replyReceived && loggedIn ? false : true}
              onKeyDown={(e) => {
                handleKeyDown(e);
              }}
              id="chat-input"
              onChange={(e) => {
                props.username && setUsernameInput(e.target.value);
                props.email && setEmailInput(e.target.value);
                props.email && console.log("Email",emailInput);
                props.username && console.log("Username",usernameInput);
              }}
            />
            <motion.div
              whileHover={{ scale: (usernameInput !== "" || emailInput !== "") && 1.1 }}
              whileTap={{ scale: (usernameInput !== "" || emailInput !== "") && 0.9 }}
              initial={{ scale: 0 }}
              animate={{ rotate: 360, scale: 1 }}
              transition={{
                type: "spring",
                stiffness: 260,
                damping: 20,
                delay: loaded ? 0 : 0.4,
              }}
              style={{
                display: "flex",
              }}
              onClick={() => {
                emailInput !== "" || usernameInput !== "" && sendInput();
              }}
            >
              <div
                className={`send-button ${(emailInput === "" && usernameInput === "") ? "send-button-deactive" : "send-button-active"}`}
              >
                <img src={Send} alt="Send" />
              </div>
            </motion.div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ChatboxMini;
