import React, { useEffect } from "react";
import Button from "@mui/material/Button";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useStoreState, useStoreActions } from "easy-peasy";
import { TypeAnimation } from "react-type-animation";
import FaceOne from "../../Components/RobotFaces/FaceOne/FaceOne";
import "./Zappy.scss";
import { useParams } from "react-router-dom";

const theme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#000",
      contrastText: "#FFF",
    },
    secondary: {
      main: "#147cff",
      contrastText: "#000",
    },
  },
});

const Home = () => {
  const { id } = useParams();

  const colorMode = useStoreState((state) => state.colorMode);
  const navPCHeight = useStoreState((state) => state.navPCHeight);
  const botData = useStoreState((state) => state.botData);
  const setPageNumber = useStoreActions((actions) => actions.setPageNumber);

  const [loaded, setLoaded] = React.useState(false);

  useEffect(() => {
    console.log("Bot Data", botData);
    botData["pages"] != [] && setLoaded(true);
  }, [botData]);

  return (
    <ThemeProvider theme={theme}>
      {loaded && (
        <div
          className="home-wrapper"
          style={{
            minHeight: `calc(100vh - ${navPCHeight}px)`,
            marginTop: `${navPCHeight}px`,
            backgroundColor:
              colorMode === "dark"
                ? botData["template"]["background"]
                : botData["template"]["background_dark"],
          }}
        >
          <div className="home-main">
            <div
              className="home-left"
              style={{
                color:
                  colorMode === "dark"
                    ? botData["template"]["welcome_message_color"]
                    : botData["template"]["welcome_message_color_dark"],
              }}
            >
              <div className="text-big">
                {loaded && (
                  <TypeAnimation
                    sequence={[
                      botData["welcome_message"]?.split("\\n").join("\n"),
                      0,
                    ]}
                    wrapper="div"
                    speed={50}
                    repeat={0}
                    style={{ whiteSpace: "pre-line" }}
                  />
                )}
              </div>
              <div className="mobile-face">
                    <FaceOne />
              </div>
              <div className="text-small">
                <TypeAnimation
                  sequence={[botData["subtext"]?.split("\\n").join("\n"), 0]}
                  wrapper="div"
                  speed={50}
                  repeat={0}
                  cursor={false}
                />
              </div>
              <div className="left-button">
                <Button
                  onClick={() => {
                    console.log("Clicked");
                    // window.location.href = `/${id}/chat`;
                    setPageNumber(1);
                  }}
                  variant="contained"
                  sx={{
                    height: "3.375rem",
                    width: "11.375rem",
                    fontFamily: "helvetica neue",
                    fontSize: "1.075rem",
                    textTransform: "none",
                    fontWeight: "400",
                    color:
                      colorMode === "dark"
                        ? botData["template"]["button_text_color_dark"]
                        : botData["template"]["button_text_color"],
                    boxShadow: "none",
                    backgroundColor:
                      colorMode === "dark"
                        ? botData["template"]["button_color_dark"]
                        : botData["template"]["button_color"],
                  }}
                >
                  Get Started
                </Button>
              </div>
            </div>
            <div className="home-right">
              <div
                className="text"
                style={{
                  color:
                    colorMode === "dark"
                      ? botData["template"]["zappy_eyes"]
                      : botData["template"]["zappy_eyes_dark"],
                }}
              >
                <FaceOne />
              </div>
            </div>
          </div>
        </div>
      )}
    </ThemeProvider>
  );
};

export default Home;
